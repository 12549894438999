function resetFormExerciseList(e) {
    e.preventDefault();
    window.edit = false;
    $("#description_en").val(" ");
    $("#description_nl").val(" ");
    $("#title_en").val(" ");
    $("#title_nl").val(" ");
    window.fillExerciseListSettings({ id: 0, content: [] });

    $("#list-preview").css("display", "none");
    $("#image_list").val("");
}
window.resetFormExerciseList = resetFormExerciseList;

function fillExerciseListSettings(data) {
    // window.sendMessageURL = "/admin/list/update";
    window.nExercises = 0;
    window.formList = [];
    window.listId = data.id;
    window.exerciseSchedules = [];

    $("#edit-exercise-swal").remove(); // remove the swal, so the addFormList uses the right content
    $(".dragable-exercise").removeClass("active");
    $(".dragable-exercise").find(".checkbox i").text("check_box_outline_blank");
    $(".exercise-destination").html(""); // NUKES

    // Set the scheduled content
    window.exerciseSchedules = data.content.map(function (d) {
        return d.schedule;
    });

    // $("#" + target + "Modal input[type='checkbox']").prop("checked", false);
    for (let x = 0; x < data.content.length; x += 1) {
        const content = data.content[x];
        window.addExerciseToList(`[name=exercise-${content.id}]`);
    }
}
window.fillExerciseListSettings = fillExerciseListSettings;

/**
 * Removes the image of an exercise list
 */
function deleteListImage() {
    $("#list-preview").css("display", "none");
    $("#image_list_preview").attr("src", "");
    $("#image_list").val("");

    const userType = window.therapist_custom === true ? "therapist" : "admin";

    if (window.listId != null && window.listId !== 0) {
        $.ajax({
            url: `/${userType}/list/remove/image`,
            method: "post",
            headers: {
                "X-CSRF-TOKEN": $("meta[name='csrf-token']").attr("content")
            },
            data: { id: window.listId }
        });
    }
}
window.deleteListImage = deleteListImage;
